



















































































































/* eslint-disable @typescript-eslint/no-explicit-any */

import Vue from "vue";
import firebase from "@/plugins/firebase";
import CregDetail from "../users/CregDetail.vue";

type DownloadItem = {
  "Kullanıcı Adı": string;
  Tamamlanan: string;
  "Başlangıç Tarihi": string;
  "Bitiş Tarihi": string;
};

export default Vue.extend({
  components: {
    CregDetail
  },
  props: {
    course: {
      type: Object,
      required: true
    },
    businessRegUids: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      tab: null,
      search: "",
      loading: false,
      headers: [
        {
          text: "Kullanıcı",
          align: "start",
          filterable: true,
          value: "fullName"
        },
        { text: "Tamamlanan", value: "progress", width: "120px" },
        { text: "Baş. Tar.", value: "dateCreated", width: "120px" },
        { text: "Bit. Tar.", value: "dateGraduated", width: "120px" },
        { text: "", value: "actions", sortable: false, width: "50px" }
      ],
      items: [] as unknown[],
      dialogCert: false,
      certUrl: "",
      downloadData: [] as DownloadItem[]
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },

    closeDialogCert() {
      this.certUrl = "";
      this.dialogCert = false;
    },

    // En son yapılan başarılı sınavın tarihini bulur
    // ve ders kaydının mezuniyet tarihini günceller.
    async findDateGraduated(cregId: string, uid: string, courseId: string) {
      try {
        let returnVal = null;
        const quizQuery = await firebase
          .firestore()
          .collection("quizzes")
          .where("uid", "==", uid)
          .where("courseId", "==", courseId)
          .where("status", "==", "passed")
          .orderBy("dateCreated", "desc")
          .limit(1)
          .get();

        if (!quizQuery.empty) {
          const dateCreated = quizQuery.docs[0].data().dateCreated;

          // Mezuniyet tarihini kaydet
          const cregRef = firebase
            .firestore()
            .doc(`businessCourseRegistrations/${cregId}`);

          await cregRef.update({
            dateGraduated: dateCreated
          });

          returnVal = dateCreated.toDate();
        }

        return returnVal;
      } catch (e) {
        const err = e as firebase.firestore.FirestoreError;
        console.error(`Mezuniyet tarihi aranırken hata:  ${err.message}`);
      }
    },

    // Ders kaydının mezuniyet tarihini null yapar.
    async resetDateGraduated(cregId: string) {
      try {
        // Mezuniyet tarihini kaydet
        const cregRef = firebase
          .firestore()
          .doc(`businessCourseRegistrations/${cregId}`);

        await cregRef.update({
          dateGraduated: null
        });
      } catch (e) {
        const err = e as firebase.firestore.FirestoreError;
        console.error(`HATA: Mezuniyet tarihi sıfırlanamadı.  ${err.message}`);
      }
    },

    async getItems() {
      this.loading = true;

      try {
        this.items = [];

        // uid'leri tara. Firebase in sorgusunda max. 10 öğeye izin veriyor
        const limit = 10;
        for (let i = 0; i < this.businessRegUids.length / limit; i++) {
          const start = i * limit;
          const finish = start + limit;
          const uids = this.businessRegUids.slice(start, finish);

          // Get all courses
          const regQuery = await firebase
            .firestore()
            .collection("businessCourseRegistrations")
            .where("uid", "in", uids)
            .where("courseId", "==", this.course.id)
            .orderBy("dateCreated")
            .get();

          regQuery.forEach(async regDoc => {
            if (regDoc.exists) {
              const reg = regDoc.data();
              reg.id = regDoc.id;

              const newItem = {
                id: regDoc.id,
                progress: reg.progress,
                dateCreated: reg.dateCreated.toDate(),
                dateGraduated: reg.dateGraduated
                  ? reg.dateGraduated.toDate()
                  : null,
                isActive: reg.isActive,
                courseId: reg.courseId,
                courseName: this.course.name,
                completedLessons: reg.completedLessons,
                completedLessonItems: reg.completedLessonItems,
                uid: reg.uid,
                firstName: "",
                lastName: "",
                fullName: "",
                email: ""
              };

              // Eğer eğitim tamamlanmış ama
              // mezuniyet tarihi kayıtlı değil ise
              // en son yapılan başarılı sınavın tarihini
              // mezuniyet tarihi olarak kullan.
              if (reg.progress === 100) {
                newItem.dateGraduated = await this.findDateGraduated(
                  regDoc.id,
                  reg.uid,
                  reg.courseId
                );
              } else {
                await this.resetDateGraduated(regDoc.id);
              }

              const business = this.$store.getters["auth/business"];

              const userQuery = await firebase
                .firestore()
                .collection("businessUserInvitations")
                .where("uid", "==", newItem.uid)
                .where("businessRegId", "==", business.regId)
                .limit(1)
                .get();

              if (!userQuery.empty) {
                const userInv = userQuery.docs[0].data();
                newItem.firstName = userInv.firstName;
                newItem.lastName = userInv.lastName;
                newItem.fullName = userInv.firstName + " " + userInv.lastName;
                newItem.email = userInv.email;
              }

              this.items.push(newItem);

              // İndirme verisini oluştur
              const downloadItem = {
                "Kullanıcı Adı": newItem.fullName,
                Tamamlanan: newItem.progress,
                "Başlangıç Tarihi": newItem.dateCreated.toLocaleDateString(
                  "tr-TR"
                ),
                "Bitiş Tarihi":
                  newItem.dateGraduated === null
                    ? ""
                    : newItem.dateGraduated.toLocaleDateString("tr-TR")
              } as DownloadItem;

              this.downloadData.push(downloadItem);
            }
          });
        }

        this.loading = false;
      } catch (e) {
        const err = e as firebase.firestore.FirestoreError;
        this.loading = false;
        this.$notify({
          type: "error",
          text: `Kullanıcı listesi alınamadı. ${err.code} ${err.message}`,
          duration: -1
        });

        console.log(
          `HATA: Kullanıcı listesi alınamadı. ${err.code} ${err.message}`
        );
      }
    },

    async getCertUrl(cregId: string) {
      return await firebase
        .storage()
        .ref(`certificates/${cregId}.pdf`)
        .getDownloadURL();
    },

    async getCert(creg: any) {
      // Dersi tamamlamamışsa kullanıcıyı bilgilendir
      if (creg.progress < 100) {
        this.$notify({
          title: "Eğitim tamamlanmadı!",
          text: `Katılım sertifikası alabilmek için tüm
          modüllerin tamamlanması gereklidir.`
        });
        return;
      }

      try {
        this.certUrl = await this.getCertUrl(creg.id);
        this.dialogCert = true;
      } catch {
        this.$notify({
          title: "Lütfen bekleyin!",
          text: "Sertifika oluşturuluyor...",
          duration: -1
        });

        // Sertifikayı oluştur
        const createCertificate = firebase
          .functions()
          .httpsCallable("certificate-createBusinessCertificate");

        await createCertificate({
          cregId: creg.id
        });
      }

      // Her 1 sn'de sertfikayı kontrol et.
      const timer = setInterval(async () => {
        try {
          this.certUrl = await this.getCertUrl(creg.id);

          if (this.certUrl) {
            this.$notify({ clean: true });
            this.dialogCert = true;

            clearInterval(timer);
          }
        } catch {
          console.error("Sertifika bulunamadı");
        }
      }, 1000);
    }
  },

  async mounted() {
    await this.getItems();
  }
});
